import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Movie Selecting App" link="https://still-darkness-1691.fly.dev/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Full-stack web app, full CRUD, data comes from external API, built with Remix framework, uses Prisma ORM and Postgres. Main purpose of the app is to shorten the process of movie selection by narrowing down individual user or group viewing preferences.
    </ProjectCard>
    <ProjectCard title="Beer Company" link="https://silly-tesla-b0faf4.netlify.app/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  This app helps keep track of beer consumption at a busy pub. The application was built with React, most of the styling was done with Bootstrap and custom CSS.
    </ProjectCard>
    <ProjectCard title="Duck shoping cart" link="https://tender-curie-d8ce11.netlify.app/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Ecommerce shopping cart example, built with React and styled with react-bootstrap package.
    </ProjectCard>
    <ProjectCard title="US Education Level Map" link="https://zealous-jones-c5db9a.netlify.app/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  This project is intended to provide an insight into education levels across the US by county. Choropleth map built with D3, HTML, SVG, and CSS.
    </ProjectCard>
 <ProjectCard title="Real-Time Messaging App" link="https://katchatify.netlify.app/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Full-stack messaging application, built with Node.js and React. Users can authenticate, create custom messaging channels, send direct messages to one another.  
    </ProjectCard>
    <ProjectCard title="Recipe Collection" link="https://dazzling-minsky-5ac3f3.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Recipe collection web app, build with React. The main purpose is to demonstrate the use of React hooks, specifically state management and interaction with NoSQL data stored in Firebase.
    </ProjectCard>
    <ProjectCard title="Smart Money" link="https://finance-tracker-311e3.web.app/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Expense tracking app, built with React, uses hooks to manage state, Firebase is used to authenticate and keep track of expenses based on user id stored in Firestore. Firebase is also used for data persistency.
    </ProjectCard>
    <ProjectCard title="Project Management App" link="https://projectzilla-5192f.web.app" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Asana-like project management app, logged-in users are able to collaborate and assign tasks to each other. Built with React and Firebase, the latter is used for authentication and storage purposes. 
    </ProjectCard>
    <ProjectCard title="Memory Game" link="https://amazing-yalow-31fb26.netlify.app/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Simple memory card game, built with React. Lets users select two cards at a time, in attempt to match the pair.
    </ProjectCard>
    <ProjectCard title="Sound of a Drum" link="https://goofy-mcnulty-e3b785.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Become a rock star drum player. Play a drum sound by pressing any of ASDFGHJKL keyboard keys.
    </ProjectCard>
    <ProjectCard title="Analog Clock" link="https://clock-on-the-wall.netlify.app" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Enjoy amazing view of a beautiful clock.
    </ProjectCard>
    <ProjectCard title="PSD to HTML with CSS" link="https://stoic-gates-2c71b0.netlify.app/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  This project was created to document website creation based on photoshop mock-up template. Original psd file can be found <a href="http://www.ianbarnard.co.uk/free-psd-templates/free-psd-web-template-green-design/" target="_blank">here</a>.
    </ProjectCard>
    <ProjectCard title="Flex Panles" link="https://awesome-agnesi-e271fd.netlify.app/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Use case of CSS Flex functionality to expand image panels on the screen.
    </ProjectCard>
    <ProjectCard title="Galactic Age Calculator" link="https://elated-bell-30d3d7.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A solar year lasts 365 days on planet Earth. However, the length of a solar year varies from planet to planet. This application determines a user's age based on a planet's solar years.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      