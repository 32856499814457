import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`About`}</h4>
    <blockquote>
      <p parentName="blockquote">{`I'm outgoing, dedicated, and open-minded. I get across to people and adjust to changes with ease. `}</p>
    </blockquote>
    <p>{`I believe that any person should work on developing their professional skills and learning new things all the time. Currently, I'm building UI rich web experiences, Next.js is next ... pun was inteded :) `}</p>
    <p>{`Usually, if I cannot be found coding new app or learning new skill, I'm most likely: pursuing outdoor adventures, enjoying good coffee with friends or watching indie flix with family.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      